import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Section, Container } from '@components/global';
import Img from 'gatsby-image';

const ServiceSection = props => {
  const { imageFluid } = props;

  const inverse = props.inverse != null && props.inverse;

  const readMore = props.readmore && (
    <Link to={props.readmore}>Mehr lesen</Link>
  );

  return (
    <Section id="services">
      <StyledContainer background={props.background}>
        <Grid isInverse={inverse}>
          <StyledLetter isInverse={inverse}> {props.letter}</StyledLetter>
          <Art>
            <Img fluid={imageFluid}></Img>
          </Art>
          <Text isInverse={inverse}>
            {props.children} {readMore}
          </Text>
        </Grid>
      </StyledContainer>
    </Section>
  );
};

const StyledLetter = styled.div`

grid-area: areaLetter;
height: 170px;
margin-top: -70px;
margin-bottom: 16px;

${props => (props.isInverse ? 'margin-left: 16px;' : 'margin-left: 0px;')}

  @media (max-width: ${props => props.theme.screen.md}) {
    height: 150px;
    margin-top: -65px;
    margin-left: 16px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 130px;
    margin-top: -55px;
    margin-left: 16px;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    height: 120px;
    margin-top: -50px;
    margin-left: 16px;
    margin-right: 5%;
  }
`;

const StyledContainer = styled(Container)`
  background: ${props => props.background};
  overflow: visible;

  a {
    color: ${props => props.theme.color.white.regular};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 5fr 5fr;

  grid-template-areas: ${props =>
    props.isInverse
      ? '"areaLetter areaImage" "areaText areaImage"'
      : '"areaImage areaLetter" "areaImage areaText"'};

  grid-gap: 32px;
  border: 0px;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'areaLetter'
      'areaImage'
      'areaText';
    grid-gap: 0px;

    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const Text = styled.div`
grid-area: areaText;

color: rgba(255,255,255,0.90);
font-weight: 300;
${props => props.theme.font_size.regular};
padding-top: 16px;
margin-bottom: 16px;

${props => (props.isInverse ? 'margin-left: 16px;' : 'margin-right: 16px;')}

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;

    text-align: center;
    order:  ${props => (props.isInverse ? '2' : '1')};
  }
`;

const Art = styled.figure`
  grid-area: areaImage;

  margin-block-start: 32px;
  margin-block-end: 32px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-bottom: 0px;
    margin-block-start: 16px;
    margin-block-end: 16px;
  }
`;
export default ServiceSection;
