import React from 'react';
import { Container, Row, StyledH1 } from '@components/global';
import { useStaticQuery, graphql } from 'gatsby';
import ServiceSection from '@components/sections/ServiceSection';
import { ReactComponent as RenovationsLetter } from '@images/Renovierung.svg';
import { ReactComponent as ReconstructionLetter } from '@images/Umbau.svg';
import { ReactComponent as DemolitionLetter } from '@images/Abbruch.svg';
import { ReactComponent as PaintingLetter } from '@images/Malerarbeiten.svg';

function Services() {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          name: {
            in: ["umbau-hero", "abbruch-hero", "maler-hero", "renovierung-hero"]
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          id
          name
        }
      }
    }
  `);

  return (
    <Container>
      <Row>
        <StyledH1>Unsere Leistungen</StyledH1>
      </Row>

      <Row>
        <ServiceSection
          background="#004271"
          imageFluid={
            images.nodes.find(n => n.name === 'renovierung-hero')
              .childImageSharp.fluid
          }
          readmore="/renovierung"
          letter={<RenovationsLetter preserveAspectRatio="xMinYMin meet" />}
        >
          Wir begleiten Sie bei Ihren Projekten rund um die Themen
          Malerarbeiten, Fliesen- und Fußbodenverlegung und helfen bei der
          Planung Ihrer Räume.
        </ServiceSection>
      </Row>

      <Row>
        <ServiceSection
          inverse
          background="#006D67"
          imageFluid={
            images.nodes.find(n => n.name === 'abbruch-hero').childImageSharp
              .fluid
          }
          readmore="/abbruch"
          letter={<DemolitionLetter preserveAspectRatio="xMinYMin meet" />}
        >
          Abbruch ist ein Bestandteil des Gebäudelebenszyklus. Als Allrounder am
          Bau, schaffen wir Modernisierung durch Abbruch, ohne dabei in die
          Statik einzugreifen. Entkernung für Sanierung, Umbau und Renovierung.
          Sprechen Sie uns an!
        </ServiceSection>
      </Row>

      <Row>
        <ServiceSection
          background="#007251"
          imageFluid={
            images.nodes.find(n => n.name === 'umbau-hero').childImageSharp
              .fluid
          }
          readmore="/umbau"
          letter={<ReconstructionLetter preserveAspectRatio="xMinYMin meet" />}
        >
          Umbauen ist ein Abenteuer! Starten Sie gemeinsam mit uns die
          Abenteuerreise und verwirklichen Ihre zeitgemäßen Wohnansprüche. Gerne
          stehen wir an Ihrer Seite und beraten Sie bei Ihren Wünschen und
          Vorstellungen.
          {/* Wir schaffen eine tolle Verwandlung in Ihrem
            Haus, ihrer Wohnung, oder in ihrem Gewerbe! */}
        </ServiceSection>
      </Row>

      <Row>
        <ServiceSection
          inverse
          background="#222660"
          imageFluid={
            images.nodes.find(n => n.name === 'maler-hero').childImageSharp
              .fluid
          }
          readmore="/malerarbeiten"
          letter={<PaintingLetter preserveAspectRatio="xMinYMin meet" />}
        >
          Vom ersten Pinselstrich bis zur fertigen Hausfassade! Wir schaffen
          Lebensqualität durch Farbe. Professionalität und handwerkliche
          Perfektion stehen bei uns an erster Stelle. Ihrer Kreativität sind
          keine Grenzen gesetzt.
          {/*  Ob kreative Spachtel- und
            Lasurtechniken oder Trockenbaugestaltung, wir helfen Ihnen gerne
            beim Farb- und Gestaltungskonzept.
            <br /> <br />  Ebenso bieten wir Fassadensanierung sowie Neugestaltung der Fassade, Wärmedämmverbundsysteme oder Perimeterdämmung (Erdberührter Bauteile), an. */}
        </ServiceSection>
      </Row>
    </Container>
  );
}

export default Services;
